import React from "react";
import "./Button.scss";

export interface ButtonProps {
  id: string;
  name: string;
  label: string;
  className?: string;
  placeholder?: string;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      id={props.id}
      name={props.name}
      className={props.className}
      placeholder={props.placeholder}>
      {props.label}
    </button>
  );
};

export default Button;
